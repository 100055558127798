<template>
  <div class="round-table position-relative">
    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr class="bg-info">
          <th width="25%">สถิติผลรางวัล</th>
          <th width="30%">5 งวดล่าสุด</th>
          <th width="15%">3 ตัวบน</th>
          <th width="15%">2 ตัวบน</th>
          <th width="15%">2 ตัวล่าง</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items">
          <td>{{ item.note.marketTitle }}</td>
          <td>{{ item.roundDate.date | dispDateTime("DD-MM-YYYY") }}</td>
          <td>{{ item.results.threeNumberTop[0] }}</td>
          <td>{{ item.results.twoNumberTop[0] }}</td>
          <td>{{ item.results.twoNumberBottom[0] }}</td>
        </tr>
      </tbody>
    </table>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'

export default {
  name: 'RecentResults',
  props: ['marketId'],
  data() {
    return {
      data: null,
      isLoading: false,
      isFail: false
    }
  },
  computed: {
    items() {
      return this.data || []
    }
  },
  watch: {
    marketId() {
      if(this.marketId) {
        this.getResults()
      }
    }
  },
  methods: {
    getResults() {
      this.isLoading = true
      this.isFail = false
      ReportService.getMarketResults(this.marketId, 5).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.isFail = true
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.round-table {
  background-color: #FFF;
}
table {
  thead {
    tr {
      th {
        font-size: 85%;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 85%;
        text-align: center;
      }
    }
  }
}
</style>
