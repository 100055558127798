import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class ReportService {

  /**
   * ดึงรายการโพย
   */
  getTickets(limit=15) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'ticket/' + limit)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการแทง
   */
  getTicket(ticketId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'ticket/' + ticketId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ยกเลิกโพย
   */
  cancelTicket(ticketId, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'ticket/' + ticketId, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการโพยตามวันที่
   */
  getTicketsDate(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}report/ticket/${params.startDate}/${params.endDate}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการบัญชี
   */
  getTransactions(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}report/transaction/${params.startDate}/${params.endDate}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงโพยจากสถานะ
   */
  getTicketWithStatus(status) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}report/ticket/${status}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงโพยจากสถานะ และประเภทหวย
   */
  getTicketWithLottoIds(status, params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_BACKEND_API}report/ticket/${status}/${params.startDate}/${params.endDate}`, {marketIds: params.marketIds})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงผลหวย
   */
  getMarketResults(marketId, limit=5) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}market/result/${marketId}/${limit}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ตรวจผลรางวัล
   */
  getResults(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}report/result/${params.startDate}/${params.endDate}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงโพยผลหวย
   */
  getRoundTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_BACKEND_API}report/result/${roundId}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

}

export default new ReportService()
