<template>
  <div>
    <div class="card alert-primary border-primary mb-3">
      <div class="card-body p-3">
        <h5 class="d-flex justify-content-between">
          <span>
            <span class="text-blue">[{{roundGroupName}}]</span>
            <span class="text-blue"> {{roundMarketName}}</span>
          </span>
          <span>{{roundDate}}</span>
        </h5>
        <div class="form-inline mt-2 d-flex justify-content-between">
          <div>
            <span class="my-1 mr-2">อัตราจ่าย:</span>
            <b-form-select v-model="selectdRateId" :options="rateOptions"></b-form-select>
            <router-link :to="{name: 'Profile'}" class="btn btn-link my-1 border-0">ดูรายละเอียด</router-link>
          </div>
          <div v-if="roundIcon" class="float-right">
            <img :src="roundIcon" style="width: 60px;">
          </div>
        </div>
      </div>
    </div>

    <b-card no-body class="border-primary mb-3" header-tag="nav">
      <b-card-header header-tag="nav" class="alert-primary">
        <b-nav card-header pills>
          <b-nav-item
            :active="avtiveTab==='expressForm'"
            :link-classes="[{'bg-primary text-light':avtiveTab==='expressForm'}]"
            @click="toggleTab('expressForm')"
          >แทงเร็ว</b-nav-item>
          <b-nav-item
            :active="avtiveTab==='tableForm'"
            :link-classes="[{'bg-primary text-light':avtiveTab==='tableForm'}]"
            @click="toggleTab('tableForm')"
          >3ตัว/2ตัว/เลขวิ่ง</b-nav-item>
          <b-nav-item
            :active="avtiveTab==='bulk'"
            :link-classes="[{'bg-primary text-light':avtiveTab==='bulkForm'}]"
            @click="toggleTab('bulkForm')"
          >วางโพย</b-nav-item>
        </b-nav>
      </b-card-header>
    </b-card>

    <b-card
      v-if="avtiveTab==='expressForm'"
      no-body
      class="border-primary mb-3"
    >
      <ExpressForm
        :round="round"
        :bills="bills"
        @updateBill="updateBill"
      />
    </b-card>

    <TableForm
      v-if="avtiveTab==='tableForm'"
      :tableNumbers="tableNumbers"
    />

    <b-card
      v-if="avtiveTab==='bulkForm'"
      no-body
      class="border-primary mb-3"
    >
      <BulkForm
        :round="round"
        :bills="bills"
        @updateBill="updateBill"
      />
    </b-card>

    <div class="row justify-content-center">
      <div class="col">
        <div class="d-flex justify-content-center mb-2">
          <label for="inlineFormCustomSelectPref" class="my-1 mr-2">หมายเหตุ:</label>
          <input type="text" class="form-control" v-model="ticket.remarks">
        </div>
      </div>
      <div v-if="roundIcon" class="col-auto">
        <div class="text-right">
          <img :src="roundIcon" height="35" class="shadow-sm float-right">
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-2"></div>
      <div class="col-8">
        <h5 class="text-center mb-0">
          <span>[{{roundGroupName}}]</span>
          <span> {{roundMarketName}}</span>
          <span> - {{roundDate}}</span>
        </h5>
        <h4 class="text-center">รวม {{ticketAmount}} บาท</h4>
      </div>
      <div class="col-2">
        <h6 class="text-danger text-right time-right">
          <span>เหลือเวลา</span>
          <span> {{roundTimer}}</span>
        </h6>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <button class="btn btn-danger" @click="toggleClearBills">ล้างตาราง</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click="preSaveTicket">บันทึก</button>
      </div>
    </div>
  </div>
</template>
<script>
import ExpressForm from './express/ExpressForm'
import TableForm from './table/TableForm'
import BulkForm from './bulk/BulkForm'

import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'

import moment from '@/helpers/moment'

const defaultRoundSettingNumbers = {
  threeNumberTop: [],
  threeNumberTode: [],
  twoNumberTop: [],
  twoNumberBottom: [],
  runTop: [],
  runBottom: []
}

export default {
  name: 'RoundKeyNumbers',
  components: {
    ExpressForm,
    TableForm,
    BulkForm
  },
  props: ['round', 'rateId', 'roundTimer', 'bills', 'tableNumbers', 'ticket'],
  data() {
    return {
      roundId: this.$route.params.roundId,
      selectdRateId: null,
      avtiveTab: 'expressForm'
    }
  },
  computed: {
    roundGroupName() {
      if(!this.round)
        return ''

      return this.round?.note.groupTitle
    },
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    rateOptions() {
      if(!this.round)
        return []

      return (this.round?.rates||[]).reduce((options, rate)=>{
        options.push({
          value: rate._id,
          text: rate.rateTitle
        })
        return options
      }, [])
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    ticketAmount() {
      /**
       * ยอดคีย์ใน แทงเร็ว + วางโพย
       */
      const amount = this.bills.reduce((total, bill)=>{
        console.log(bill.type, bill.billAmount)
        total += bill.billAmount
        return total
      }, 0)

      /**
       * ยอดคีย์ใน 3ตัว/2ตัว/เลขวิ่ง
       */
      const totalAmount = this.tableNumbers.reduce((total, row)=>{

        const amountTop = row.amountTop || 0
        const amountBot = row.amountBot || 0
        const amountTod = row.amountTod || 0

        if(row.number.length === 1) {
          total += (amountTop + amountBot)
        }else
        if(row.number.length === 2) {
          total += (amountTop + amountBot)
        }else
        if(row.number.length === 3) {
          total += (amountTop + amountTod)
        }

        return total
      }, amount)

      return totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    },
    closeNumbers() {
      return this.round?.closeNumbers || defaultRoundSettingNumbers
    },
    payHalfNumbers() {
      return this.round?.payHalfNumbers || defaultRoundSettingNumbers
    }
  },
  watch: {
    round(n, o) {
      /**
       * สำหรับอัพเดตค่าที่มีการเปลี่ยนแปลงจากการ reload backend
       */
      if(!this.selectdRateId) {
        console.log('#1.1')
        /**
         * เปิดหน้าแทงครั้งแรก ไม่มี rateId ให้ใช้อัตราจ่ายลำดับแรกสุด
         */
        this.selectdRateId = n.rates[0]._id
      }else{
        console.log('#1.2')
        /**
         * อัพเดตค่าอัตราจ่ายที่มีการเปลี่ยนแปลงจาก backend
         */
        this.updateRate()
      }
    },
    selectdRateId(n, o) {
      console.log('#3')
      /**
       * ส่งค่า rateId ไปเก็บที่หน้าหลัก เพื่อรับค่า rateId หลังจากกด back จากหน้ายืนยัน
       */
      this.$emit('rateId', n)
      /**
       * เมื่อมีการเปลี่ยนแปลง selectdRateId ให้อัพเดตอัตราจ่าย
       */
      this.updateRate()
    }
  },
  methods: {
    updateRate() {
      if(this.selectdRateId) {
        const rate = this.round.rates.find((r)=>{
          return r._id === this.selectdRateId
        })
        if(rate) {
          this.$store.commit('updateRound', {
            roundId: this.roundId,
            groupTitle: this.round.group.groupTitle,
            marketTitle: this.round.market.marketTitle,
            rate: rate
          })
        }else{
          this.$store.commit('updateRound', {
            roundId: this.roundId,
            groupTitle: this.round.group.groupTitle,
            marketTitle: this.round.market.marketTitle,
            rate: null
          })
        }
      }else{
        this.$store.commit('updateRound', {
          roundId: this.roundId,
          groupTitle: this.round.group.groupTitle,
          marketTitle: this.round.market.marketTitle,
          rate: null
        })
      }
    },
    toggleTab(tab) {
      this.avtiveTab = tab
    },
    updateBill(bills) {
      this.bills = bills
    },
    toggleClearBills() {
      this.$emit('clear')
    },
    preSaveTicket() {

      /**
       * รายการคีย์จากฟอร์ม 3ตัว/2ตัว/เลขวิ่ง
       */
      const inputTableNumbers = this.tableNumbers.filter((row)=>{
        return row.number.length > 0 && (row.amountTop || row.amountBot || row.amountTod)
      })

      if(this.bills.length === 0 && inputTableNumbers.length === 0)
        return Swal.fire({
          text: 'ยังไม่ได้แทงเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })

      /**
       * เลข 3 ตัว
       */
      const rowsThreeTop = []
      const rowsThreeTod = []
      /**
       * เลข 2 ตัว
       */
      const rowsTwoTop = []
      const rowsTwoBot = []
      /**
       * เลขวิ่ง
       */
      const rowsRunTop = []
      const rowsRunBot = []

      /**
       * รายการบิลจากฟอร์ม แทงเร็ว + วางโพย
       */
      for(const bill of this.bills) {

        /**
         * เลขในบิล
         */
        for(const number of bill.numbers) {
          /**
           * เลข 3 ตัว
           */
          if(bill.type === 'threeNumber') {

            /**
             * 3 ตัวบน
             */
            if(this.isOpenThreeTop && bill.amountTop > 0) {

              const min = this.rate.betLimit.threeNumberTop.min
              const max = this.rate.betLimit.threeNumberTop.max
              const numberAmount = bill.amountTop > max ? max : bill.amountTop
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.threeNumberTop.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.threeNumberTop.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.threeNumberTop.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.threeNumberTop.discount/100))).toFixed(2))

              rowsThreeTop.push({
                type: 'threeNumberTop',
                text: '3 ตัวบน',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }

            /**
             * 3 ตัวโต๊ด
             */
            if(this.isOpenThreeTod && bill.amountTod > 0) {

              const min = this.rate.betLimit.threeNumberTode.min
              const max = this.rate.betLimit.threeNumberTode.max
              const numberAmount = bill.amountTod > max ? max : bill.amountTod
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.threeNumberTode.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.threeNumberTode.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.threeNumberTode.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.threeNumberTode.discount/100))).toFixed(2))

              rowsThreeTod.push({
                type: 'threeNumberTode',
                text: '3 ตัวโต๊ด',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }
          }

          /**
           * เลข 2 ตัว
           */
          if(bill.type === 'twoNumber') {

            /**
             * 2 ตัวบน
             */
            if(this.isOpenTwoTop && bill.amountTop > 0) {

              const min = this.rate.betLimit.twoNumberTop.min
              const max = this.rate.betLimit.twoNumberTop.max
              const numberAmount = bill.amountTop > max ? max : bill.amountTop
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.twoNumberTop.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.twoNumberTop.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.twoNumberTop.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.twoNumberTop.discount/100))).toFixed(2))

              rowsTwoTop.push({
                type: 'twoNumberTop',
                text: '2 ตัวบน',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }

            /**
             * 2 ตัวล่าง
             */
            if(this.isOpenTwoBot && bill.amountBot > 0) {

              const min = this.rate.betLimit.twoNumberBottom.min
              const max = this.rate.betLimit.twoNumberBottom.max
              const numberAmount = bill.amountBot > max ? max : bill.amountBot
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.twoNumberBottom.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.twoNumberBottom.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.twoNumberBottom.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.twoNumberBottom.discount/100))).toFixed(2))

              rowsTwoBot.push({
                type: 'twoNumberBottom',
                text: '2 ตัวล่าง',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }
          }

          /**
           * เลขวิ่ง
           */
          if(bill.type === 'runNumber') {

            /**
             * วิ่งบน
             */
            if(this.isOpenRunTop && bill.amountTop > 0) {

              const min = this.rate.betLimit.runTop.min
              const max = this.rate.betLimit.runTop.max
              const numberAmount = bill.amountTop > max ? max : bill.amountTop
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.runTop.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.runTop.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.runTop.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.runTop.discount/100))).toFixed(2))

              rowsRunTop.push({
                type: 'runTop',
                text: 'วิ่งบน',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }

            /**
             * วิ่งล่าง
             */
            if(this.isOpenRunBot && bill.amountBot > 0) {

              const min = this.rate.betLimit.runBottom.min
              const max = this.rate.betLimit.runBottom.max
              const numberAmount = bill.amountBot > max ? max : bill.amountBot
              let isValid = (numberAmount >= min)
              let note = ''
              let isWarning = false
              let pay = this.rate.openBets.runBottom.pay

              /**
               * เลขปิดรับ
               */
              if(this.closeNumbers.runBottom.includes(number)) {
                isValid = false
                note = `เลขปิดรับ`
              }else
              if(!isValid) {
                if(numberAmount < min)
                  note = `ขั้นต่ำ ${min}`
              }else
              /**
               * เลขจ่ายครึ่ง
               */
              if(this.payHalfNumbers.runBottom.includes(number)) {
                note = `จ่ายครึ่ง`
                isWarning = true
                pay = pay/2
              }

              const discount = parseFloat(((numberAmount*(this.rate.openBets.runBottom.discount/100))).toFixed(2))

              rowsRunBot.push({
                type: 'runBottom',
                text: 'วิ่งล่าง',
                number: number,
                amount: numberAmount,
                pay: pay,
                discount: discount,
                credit: (numberAmount-discount),
                isValid: isValid,
                isWarning: isWarning,
                note: note
              })
            }
          }
        }
      }

      /**
       * รายการจากฟอร์ม 3ตัว/2ตัว/เลขวิ่ง
       */
      for(const row of this.tableNumbers) {

        const amountTop = row.amountTop || 0
        const amountBot = row.amountBot || 0
        const amountTod = row.amountTod || 0

        /**
         * เลขวิ่ง
         */
        if(row.number.length === 1) {

          /**
           * วิ่งบน
           */
          if(this.isOpenRunTop && amountTop > 0) {

            const min = this.rate.betLimit.runTop.min
            const max = this.rate.betLimit.runTop.max
            const numberAmount = amountTop > max ? max : amountTop
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.runTop.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.runTop.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.runTop.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.runTop.discount/100))).toFixed(2))

            rowsRunTop.push({
              type: 'runTop',
              text: 'วิ่งบน',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }

          /**
           * วิ่งล่าง
           */
          if(this.isOpenRunBot && amountBot > 0) {

            const min = this.rate.betLimit.runBottom.min
            const max = this.rate.betLimit.runBottom.max
            const numberAmount = amountBot > max ? max : amountBot
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.runBottom.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.runBottom.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.runBottom.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.runBottom.discount/100))).toFixed(2))

            rowsRunBot.push({
              type: 'runBottom',
              text: 'วิ่งล่าง',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }
        }

        /**
         * เลข 2 ตัว
         */
        if(row.number.length === 2) {

          /**
           * 2 ตัวบน
           */
          if(this.isOpenTwoTop && amountTop > 0) {

            const min = this.rate.betLimit.twoNumberTop.min
            const max = this.rate.betLimit.twoNumberTop.max
            const numberAmount = amountTop > max ? max : amountTop
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.twoNumberTop.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.twoNumberTop.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.twoNumberTop.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.twoNumberTop.discount/100))).toFixed(2))

            rowsTwoTop.push({
              type: 'twoNumberTop',
              text: '2 ตัวบน',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }

          /**
           * 2 ตัวล่าง
           */
          if(this.isOpenTwoBot && amountBot > 0) {

            const min = this.rate.betLimit.twoNumberBottom.min
            const max = this.rate.betLimit.twoNumberBottom.max
            const numberAmount = amountBot > max ? max : amountBot
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.twoNumberBottom.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.twoNumberBottom.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.twoNumberBottom.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.twoNumberBottom.discount/100))).toFixed(2))

            rowsTwoBot.push({
              type: 'twoNumberBottom',
              text: '2 ตัวล่าง',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }
        }

        /**
         * เลข 3 ตัว
         */
        if(row.number.length === 3) {

          /**
           * 3 ตัวบน
           */
          if(this.isOpenThreeTop && amountTop > 0) {

            const min = this.rate.betLimit.threeNumberTop.min
            const max = this.rate.betLimit.threeNumberTop.max
            const numberAmount = amountTop > max ? max : amountTop
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.threeNumberTop.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.threeNumberTop.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.threeNumberTop.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.threeNumberTop.discount/100))).toFixed(2))

            rowsThreeTop.push({
              type: 'threeNumberTop',
              text: '3 ตัวบน',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }

          /**
           * 3 ตัวโต๊ด
           */
          if(this.isOpenThreeTod && amountTod > 0) {

            const min = this.rate.betLimit.threeNumberTode.min
            const max = this.rate.betLimit.threeNumberTode.max
            let numberAmount = amountTod > max ? max : amountTod
            let isValid = (numberAmount >= min)
            let note = ''
            let isWarning = false
            let pay = this.rate.openBets.threeNumberTode.pay

            /**
             * เลขปิดรับ
             */
            if(this.closeNumbers.threeNumberTode.includes(row.number)) {
              isValid = false
              note = `เลขปิดรับ`
            }else
            if(!isValid) {
              if(numberAmount < min)
                note = `ขั้นต่ำ ${min}`
            }else
            /**
             * เลขจ่ายครึ่ง
             */
            if(this.payHalfNumbers.threeNumberTode.includes(row.number)) {
              note = `จ่ายครึ่ง`
              isWarning = true
              pay = pay/2
            }

            const discount = parseFloat(((numberAmount*(this.rate.openBets.threeNumberTode.discount/100))).toFixed(2))

            rowsThreeTod.push({
              type: 'threeNumberTode',
              text: '3 ตัวโต๊ด',
              number: row.number,
              amount: numberAmount,
              pay: pay,
              discount: discount,
              credit: (numberAmount-discount),
              isValid: isValid,
              isWarning: isWarning,
              note: note
            })
          }
        }
      }

      this.ticket.rateId = this.selectdRateId
      this.ticket.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      this.ticket.rows = rowsThreeTop.concat(rowsThreeTod, rowsTwoTop, rowsTwoBot, rowsRunTop, rowsRunBot)

      console.log(this.ticket.rows)
      this.$emit('ticket', this.ticket)
    }
  },
  mounted() {
    /**
     * สำหรับกดย้อนกลับในหน้ายืนยัน แก้ปัญหา ไม่เลือกอัตราจ่ายให้
     */
    if(this.rateId && !this.selectdRateId) {
      console.log('#4')
      this.selectdRateId = this.rateId
    }
  }
}
</script>
