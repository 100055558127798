import { render, staticRenderFns } from "./RecentTickets.vue?vue&type=template&id=b2e9f606&scoped=true&"
import script from "./RecentTickets.vue?vue&type=script&lang=js&"
export * from "./RecentTickets.vue?vue&type=script&lang=js&"
import style0 from "./RecentTickets.vue?vue&type=style&index=0&id=b2e9f606&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2e9f606",
  null
  
)

export default component.exports