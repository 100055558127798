import 'regenerator-runtime/runtime'

import api from "@/services/apiService"
import Encrypt from "@/helpers/encrypt"

class LotteryService {

  /**
   * ดึงข้อมูลกลุ่มทั้งหมดในระบบ
   */
  getRounds(date=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round' + (date?'/date/'+date:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลรอบหวย
   */
  getRound(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round/' + roundId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * บันทึกโพย
   */
  createTicket(ticket) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'ticket', ticket)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

}

export default new LotteryService()
